import {
  HeatingSystem,
  HeatingSystemMetadataResponse,
  MetadataKeys,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';

export function removeLabelFromName(measurementName: string | null, metricLabel: string): string {
  if (!measurementName) return 'Unknown name';

  // match the metricLabel from the end of the measurementName
  const pattern = new RegExp(`${metricLabel}$`, 'g');

  return measurementName.replace(pattern, '').trim();
}

export function getHeatingSystemsComponents(heatingSystem: HeatingSystem): Map<string, string> {
  const heatingSystemComponents = new Map<string, string>(
    heatingSystem.components.heatingSystemMeasurementGroups.flatMap(({ heatingSystemMeasurements }) => {
      return heatingSystemMeasurements.map((measurement) => {
        return [measurement.spotId, removeLabelFromName(measurement.measurementName, measurement.metricLabel)];
      });
    }),
  );

  return heatingSystemComponents;
}

type ComponentType = {
  component: string;
  type: string;
  groupName: string;
};

export function getHeatingSystemsComponentsWithTypes(heatingSystem: HeatingSystem): Map<string, ComponentType> {
  const heatingSystemComponents = new Map<string, ComponentType>(
    heatingSystem.components.heatingSystemMeasurementGroups.flatMap(
      ({ heatingSystemMeasurements, systemComponentTypeName, groupName }) => {
        return heatingSystemMeasurements.map((measurement) => {
          const component = removeLabelFromName(measurement.measurementName, measurement.metricLabel);
          return [measurement.spotId, { component, type: systemComponentTypeName, groupName }];
        });
      },
    ),
  );

  return heatingSystemComponents;
}

export enum MetadataStatus {
  MANDATORY_INFORMATION_COMPLETE = 'MANDATORY_INFORMATION_COMPLETE',
  MANDATORY_INFORMATION_INCOMPLETE = 'MANDATORY_INFORMATION_INCOMPLETE',
}

// eslint-disable-next-line complexity
export function isComponentMetadataCompleted(component: HeatingSystemMetadataResponse): boolean {
  const metadata = component.metadata as MetadataKeys;
  switch (component.componentType) {
    case 'BOILER':
      if (typeof metadata.condensing === 'boolean') {
        return true;
      }
      return false;
    case 'WATER_HEATING':
    case 'POTABLE_WATER_HEATING':
      if (
        !metadata.circulation_target_temp ||
        !metadata.circulation_temperature_tolerance ||
        !metadata.potable_water_target_temp ||
        !metadata.potable_water_temperature_tolerance ||
        !metadata.type_of_pwh
      ) {
        return false;
      }
      return true;
    default:
      return true;
  }
}

export interface EnerIQDescription {
  de: string;
  en: string;
}

export interface EnerIQSuggestion {
  score: {
    agent_id: string;
    atoms: string[];
    component_type: string;
    identifier: string;
    metadata: {
      reason: {
        agent: string;
        operations: {
          [key: string]: {
            description: string | EnerIQDescription;
            exceptions: string[];
            reasons: Record<string, never> | null;
            severity: number;
            status_code: string;
          };
        };
      };
    };
    score: number;
    time: string;
  };
}

export interface EnerIQSuggestionList {
  coomponentType: string;
  spotId: string;
  suggestion: EnerIQSuggestion;
}

export type HeatingSystemComponentType =
  | 'boiler'
  | 'teleheating'
  | 'tele_heating'
  | 'heating circuit'
  | 'heating_circuit'
  | 'water heating'
  | 'water_heating'
  | 'warm_heating'
  | 'potable_water_heating'
  | 'buffer'
  | 'gas_meter'
  | 'heating_control'
  | 'unknown';

export const HEATING_SYSTEM_COMPONENT_NAME_MAP: Record<HeatingSystemComponentType, string> = {
  boiler: 'Kessel',
  teleheating: 'Fernwärme',
  tele_heating: 'Fernwärme',
  'heating circuit': 'Heizkreis',
  heating_circuit: 'Heizkreis',
  'water heating': 'Warmwasseraufbereitung',
  water_heating: 'Warmwasseraufbereitung',
  warm_heating: 'Warmwasseraufbereitung',
  potable_water_heating: 'Warmwasseraufbereitung',
  buffer: 'Pufferspeicher',
  gas_meter: 'Gaszähler',
  heating_control: 'Heizungssteuerung',
  unknown: 'Trinkwarmwasser',
} as const;

export function getHeatingSystemComponentName(componentType: string): string {
  const normalizedType = componentType.toLowerCase() as HeatingSystemComponentType;
  return HEATING_SYSTEM_COMPONENT_NAME_MAP[normalizedType] ?? '';
}

export enum SeverityColor {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
  GRAY = 'GRAY',
}

export function getEnerIQSeverityColor(severity: number): SeverityColor {
  if (severity >= 0 && severity <= 100) {
    // good operating state
    if (severity > 70) return SeverityColor.GREEN;
    // attention is needed
    if (severity > 30) return SeverityColor.YELLOW;
    // critical state
    return SeverityColor.RED;
  }
  // analysis has no result (lack of data, missing sensors etc.)
  return SeverityColor.GRAY;
}

export enum HeatingSystemType {
  Kessel = 'Kessel',
  Fernwärme = 'Fernwärme',
}

const commonTypes = ['WARM_HEATING', 'HEATING_CIRCUIT', 'UNKNOWN', 'BUFFER', 'HEATING_CONTROL'];

export function kesselTypes(): string[] {
  return ['BOILER', ...commonTypes];
}

export function fernwärmeTypes(): string[] {
  return ['TELE_HEATING', ...commonTypes];
}
