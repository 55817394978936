








import { FormatOptions, formatMetricValue } from '@/features/core/util/metric-formatters';
import { BooleanProp, ObjectProp, OptionalProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiMetricSpotFragment } from './__generated__/DomainUiMetricSpotFragment';

@Component
export default class MetricValue extends Vue {
  @StringProp()
  private readonly name?: string;

  @ObjectProp()
  private readonly spot?: DomainUiMetricSpotFragment;

  @OptionalProp()
  private readonly value?: string | number;

  @OptionalProp()
  private readonly unit?: string;

  @ObjectProp()
  private readonly formatOptions?: FormatOptions;

  @BooleanProp()
  private readonly inline!: boolean;

  @StringProp('–')
  private readonly emptyMessage!: string;

  // this is currently not used, but may be in the future when unit and normalization factor is configurable
  private get configuration(): DomainUiMetricSpotFragment['metricConfigurations'][number] | undefined {
    return this.spot?.metricConfigurations.find(({ name }) => name === this.name);
  }

  private get formattedValue(): string | undefined {
    if (this.configuration?.unit && this.value !== undefined) {
      if (this.configuration?.unit === '') {
        // Don't format metrics without explicit unit configuration
        return this.value.toString();
      }
      return formatMetricValue(this.name ?? '', this.value, this.formatOptions, this.configuration.unit);
    }
    return this.value === undefined
      ? undefined
      : formatMetricValue(this.name ?? '', this.value, this.formatOptions, this.unit);
  }
}
